/** @format */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BuyBookService } from '../core';
import { Book } from '../../../core';

@Injectable({
  providedIn: 'any'
})
export class OfficeServiceBuyBookResolverService {
  constructor(
    private buyBookService: BuyBookService,
    private router: Router
  ) {}

  resolve(): Observable<Book[]> {
    return this.buyBookService.getBooks()
      .pipe(
        catchError((error: any) => {
          this.router
            .navigateByUrl('/error/' + error.status)
            .then(() => console.debug('Router changed'));

          return throwError(() => new Error('OfficeServiceBuyBookResolverService error'));
        })
      );
  }
}
