<!-- @format -->

<div class="box" *ngIf="service && serviceMap" data-lenis-prevent="true">
	<div
		class="inner overflow-h position-r bordered rounded-sm bg-color-neutral_white"
		*ngIf="[].constructor(serviceMap.sliderLength) as sliderLength"
	>
		<a
			[routerLink]="serviceMap.path"
			[queryParamsHandling]="'preserve'"
			class="link-to-detail btn btn-style-1 btn-inline"
		>
			Продолжить
			<app-svg-icon
				class="ml-1"
				appIcon="arrow-right-black"
				appWidth="15"
				appHeight="15"
				appViewBox="0 0 15 14"
			></app-svg-icon>
		</a>
		<ul class="controls m-0 p-0" *ngIf="sliderLength.length > 1">
			<li
				class="d-flex cursor-p"
				[class.active]="i === serviceSlideActive"
				(click)="onChange(i)"
				*ngFor="let _ of sliderLength; let i = index"
			></li>
		</ul>
		<div
			class="overflow-h"
			appSwipe
			(swipeUp)="onChange(serviceSlideActive + 1)"
			(swipeDown)="onChange(serviceSlideActive - 1)"
			#slider
		>
			<ul
				class="slider p-0 my-0"
				[@slideAnimation]="{
					value: 'any',
					params: {
						translate: '-' + serviceSlideActive * 100 + '%'
					}
				}"
			>
				<ng-container *ngFor="let _ of sliderLength; let i = index">
					<li class="d-flex jc-center h-full w-full select-none bg-contain bg-no-repeat"
						[style.background-image]="'url(/assets/images/services/' + service.service_key + '/' + i + '.' + (slideType ?? 'jpg') + ')'"
					>
						<!--<a [routerLink]="serviceMap.path" [queryParamsHandling]="'preserve'"></a>-->
					</li>
				</ng-container>
			</ul>
		</div>
	</div>
</div>
