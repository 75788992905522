<!-- @format -->

<section class="box">
	<!--	TODO: hidden by https://kostylworks.atlassian.net/browse/RR-500 -->
	<!--<ul class="navigation p-0 my-0 d-flex jc-between w-full">
		<li class="d-flex fd-column w-full" *ngFor="let service of services">
			<a
				class="service rounded-sm text-color-log-print_text cursor-p h6 p-1"
				[class.active]="service.service_key === servicesCurrent?.service_key"
				[routerLink]="['.']"
				[queryParams]="{serviceKey: service.service_key}"
				[queryParamsHandling]="'merge'"
			>
				<img
					class="d-block mb-1"
					[src]="'/assets/images/services/' + service.service_key + '.png'"
					[alt]="service.title"
				/>
				<p
					[class.text-bold]="service.service_key === servicesCurrent?.service_key"
					class="w-75 text-center line-height-3 mb-1"
				>
					{{ service.title }}
				</p>
			</a>
		</li>
	</ul>-->
	<!--	TODO: added by https://kostylworks.atlassian.net/browse/RR-500 -->
	<app-services-carousel
		class="d-block mb-5"
		[appService]="getService('print-for-self')"
		appSlideType="jpg"
	></app-services-carousel>
	<app-services-carousel
		class="d-block mb-5"
		[appService]="getService('buy-book')"
		appSlideType="png"
	></app-services-carousel>
</section>
