/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReferenceService, Service, ServiceKeys } from '../../core';
import { combineLatest, of, Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { map, skipWhile, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-office-service',
  templateUrl: './office-service.component.html',
  styleUrls: ['./office-service.component.scss']
})
export class OfficeServiceComponent implements OnInit, OnDestroy {
  services: Service[] = [];
  servicesSubscription$: Subscription | undefined;
  servicesCurrent: Service | undefined;

  constructor(private referenceService: ReferenceService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.servicesSubscription$ = combineLatest([
      this.route.queryParams.pipe(
        map((params: Params) => params.serviceKey),

        /**
         * If serviceKey not provided set ebook
         */
        switchMap((serviceKey: ServiceKeys | undefined) => of(serviceKey || 'ebook'))
      ),
      this.referenceService.servicesSubject.pipe(
        skipWhile((services: Service[]) => !services.length),
        map((services: Service[]) => {
          this.services = services.map((service: Service, k) => ({
            id: k + 1,
            ...service
          }));

          return this.services;
        })
      )
    ])
      .pipe(
        switchMap(([serviceKey, services]) =>
          /**
           * If service by serviceKey not founded set service[0]
           */
          of(services.find(service => service.service_key === serviceKey) || services[0])
        )
      )
      .subscribe({
        next: servicesCurrent => (this.servicesCurrent = servicesCurrent),
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    this.servicesSubscription$?.unsubscribe();
  }

  getService(serviceKey: string): Service | undefined {
    return this.services.find(service => service.service_key === serviceKey);
  }
}
