/** @format */

import {
  DOCUMENT
} from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  ActivatedRoute,
  Params
} from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  forkJoin,
  fromEvent,
  of,
  Subscription
} from 'rxjs';
import {
  delay,
  filter,
  first,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import {
  Book,
  HelperService, IntlTelInputService,
  PlatformService,
  ServiceReceipt,
  ServiceReceiptRow,
  SnackbarService,
  User,
  UserService
} from 'src/app/core';
import {
  DeliveryYandex,
  DeliveryPickup
} from 'src/app/office/service/core/models/delivery.model';
import {
  AccessMessageMap,
  BookDetail,
  DeliveryService,
  PrintForSelfAccessMessageMap,
  PrintForSelfCalculate,
  PrintForSelfOrder,
  PrintForSelfPayment,
  PrintForSelfService,
  ServicesReceiptService
} from '../core';
import intlTelInput, { CountryData } from 'intl-tel-input';

interface BookForm {
  book_id: FormControl<number | null>;
}

interface PrintForm {
  book_count: FormControl<number | null>;
}

interface DeliveryForm {
  deliveryId: FormControl<number | null>;
}

interface CourierForm {
  delivery_company_id: FormControl<number | null>;  // deliveryId
  delivery_tariff_id: FormControl<number | null>;  // tariffId
  delivery_tariff_name: FormControl<number | null>;
  delivery_cost: FormControl<number | null>;  // price
  address: FormControl<string | null>;
  phone: FormControl<string | null>;
  phoneCountry: FormControl<string | null>;
  comment: FormControl<string | null>;
}

interface PickupForm {
  pickup_address_id: FormControl<number | null>;
}

interface TermsForm {
  agreement: FormControl<boolean | null>;
}

@Component({
  selector: 'app-office-services-print-for-self',
  templateUrl: './print-for-self.component.html',
  styleUrls: ['./print-for-self.component.scss'],
  animations: [
    trigger('showHide', [
      state('show', style({ opacity: 1 })),
      state('hide', style({ opacity: 0 })),
      transition('show <=> hide', animate('125ms'))
    ])
  ]
})
export class OfficeServicePrintForSelfComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('deliveryPhoneInputElement', { static: false }) deliveryPhoneInputElement!: ElementRef;

  Math = Math;

  documentActiveElement: Element | null = null;

  route$!: Subscription;
  routerQueryParams$!: Subscription;

  user$!: Subscription;
  user!: User;

  progressMap: string[] = ['initial', 'initial', 'initial', 'initial'];
  progressState = '';
  progressHistory: any = {};

  servicesReceipt: ServiceReceipt = {
    service_key: 'print-for-self',
    receipt_row: []
  };

  bookOrder: PrintForSelfOrder = {
    id: 0,
    user_id: 0,
    book_id: 0,
    book_count: 0,
    price: null,
    delivery_type: null,
    delivery_address: null,
    agreement: false
  };

  calculateBook!: PrintForSelfCalculate;

  deliveryServiceList: DeliveryYandex[] | null = null;
  deliveryPickup: DeliveryPickup[] | undefined;
  selectedDelivery: DeliveryYandex | undefined;

  books: Book[] = [];
  booksSelected: Book | undefined;

  bookDetail: BookDetail | undefined;
  bookEditionPercent = 0.1;

  bookForm: FormGroup;
  bookIdForm$: Subscription | undefined;

  qtyForm: FormGroup;
  qtyFormIsSubmitted = false;

  deliveryForm: FormGroup;
  deliveryIdForm$: Subscription | undefined;

  courierForm: FormGroup;  // fixme courierDeliveryForm
  courierAddressForm$: Subscription | undefined;
  courierDeliveryTariffForm$: Subscription | undefined;
  deliveryIsSubmitted = false;

  pickupForm: FormGroup;
  pickupFormIsSubmitting = false;

  termsForm: FormGroup;
  termsFormIsSubmitting = false;

  accessMessage: AccessMessageMap = PrintForSelfAccessMessageMap;

  modalTermsToggle = false;
  modalTermsPaymentToggle = false;

  modalPaymentsMessage: string | undefined;
  modalPaymentsStatusToggle = false;

  paymentsStatusIsActive = false;

  internationalPhoneInput!: intlTelInput.Plugin;
  internationalPhoneCountryName = 'Россия';
  examplePhoneNumber?: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private printForYourselfService: PrintForSelfService,
    private deliveryService: DeliveryService,
    private helperService: HelperService,
    private platformService: PlatformService,
    private serviceReceiptService: ServicesReceiptService,
    private snackbarService: SnackbarService,
    private elementRef: ElementRef,
    private userService: UserService,
    private phoneUtils: IntlTelInputService,
  ) {
    this.bookForm = this.formBuilder.group<BookForm>({
      book_id: this.formBuilder.control(null, [Validators.required])
    });

    this.qtyForm = this.formBuilder.group<PrintForm>({
      book_count: this.formBuilder.control(null, [
        Validators.required,
        Validators.min(30),
        // Validators.max(50)  // fixme ограничение только по весу
      ])
    });

    this.deliveryForm = this.formBuilder.group<DeliveryForm>({
      deliveryId: this.formBuilder.control(null, [Validators.required]),
    });

    this.courierForm = this.formBuilder.group<CourierForm>({
      address: this.formBuilder.control('', [Validators.required, Validators.minLength(3)]),
      delivery_company_id: this.formBuilder.control(null, [Validators.required]),
      delivery_tariff_id: this.formBuilder.control(null, [Validators.required]),
      delivery_tariff_name: this.formBuilder.control(null),
      delivery_cost: this.formBuilder.control(null, [Validators.required]),
      phone: this.formBuilder.control('', [Validators.required]),
      phoneCountry: this.formBuilder.control('ru', [Validators.required]),
      comment: this.formBuilder.control('')
    });

    this.pickupForm = this.formBuilder.group<PickupForm>({
      pickup_address_id: this.formBuilder.control(1, [Validators.required]),  // fixme временно - значение по умолчанию
    });

    this.termsForm = this.formBuilder.group<TermsForm>({
      agreement: this.formBuilder.control(false, [Validators.requiredTrue])
    });
  }

  ngOnInit(): void {
    this.documentActiveElement = this.document.activeElement;

    this.route$ = this.route.data.pipe(map((data: any) => data.data)).subscribe({
      // next: (routeData: [Book[], DeliveryPickup[]]) => {
      //   const [books, deliveryPickup] = routeData;
      //
      //   this.books = books;
      //
      //   this.deliveryPickup = deliveryPickup;
      // },
      next: (routeData: Book[]) => {
        this.books = routeData;

        this.routerQueryParams$ = this.route.queryParams.subscribe({
          next: (queryParams: Params) => {
            if ('bookId' in queryParams && !!this.books.find(book => book.id === Number(queryParams.bookId))) {
              this.bookForm.controls.book_id.setValue(Number(queryParams.bookId));
              this.onSubmitBookStep(Number(queryParams.bookId));
            }
            else if ('bookId' in queryParams) {
              this.snackbarService.error('Книга с таким ID не найдена или отсутствует книжный макет или у вас нет доступа к ресурсу');
              this.onReadyBookStep();
            }
            else if ('orderId' in queryParams && 'status' in queryParams && ['error', 'pay'].includes(queryParams.status)) {
              this.paymentsStatusIsActive = true;

              this.printForYourselfService.getCalculate(queryParams.orderId).subscribe({
                next: (data: PrintForSelfCalculate) => {
                  this.calculateBook = data;
                  this.booksSelected = this.books.find(book => book.id === data.book_id);
                  this.bookOrder.id = queryParams.orderId;
                  this.bookOrder.user_id = this.booksSelected!.user_id;
                  this.bookOrder.book_id = this.booksSelected!.id;
                  this.bookDetail = {
                    book_format: data.print.data.bookFormat.title,
                    print_type: data.print.data.printType,
                    cover_type: data.print.data.coverType.title,
                    page_count: data.print.data.page_count,
                    unit_weight: 0,
                    one_book_price: 0,
                  };
                  this.onReadyPayStep();
                },
                error: (error: any) => {
                  console.error(error);
                  this.onReadyBookStep();
                }
              });
            }
            else {
              this.onReadyBookStep();
            }
          },
          error: (error: any) => console.error(error)
        });

      },
      error: (error: any) => console.error(error)
    });

    this.user$ = this.userService.currentUser.subscribe((user: User) => {
      this.user = user;
      const userAddress = {
        address: null,
        phone: '+' + user.phone
      };
      if (user.addresses && user.addresses.length) {
        userAddress.address = user.addresses[0]?.address;
        userAddress.phone = user.addresses[0]?.phone;
      }
      this.courierForm.patchValue(userAddress);
    });

    this.registerHandlers();
  }

  ngAfterViewInit(): void {
    // this.onInitInternationalPhoneInput();
  }

  ngOnDestroy(): void {
    [
      this.route$,
      this.bookIdForm$,
      this.deliveryIdForm$,
      this.courierAddressForm$,
      this.courierDeliveryTariffForm$,
      this.routerQueryParams$,
    ].forEach($ => $?.unsubscribe());
  }

  registerHandlers(): void {
    this.bookIdForm$ = this.bookForm.controls.book_id.valueChanges
      .pipe(
        tap((bookId: number) => {
          // this.servicesReceipt.book = this.books.find(book => book.id === bookId);
        }),
        switchMap(() => this.bookForm.statusChanges),
        filter(() => this.bookForm.valid)
      )
      .subscribe({
        next: () => this.onSubmitBookStep(Number(this.bookForm.controls.book_id.value)),
        error: (error: any) => console.error(error)
      });

    // this.printCountForm$ = this.printForm.controls.book_count.valueChanges
    //   .pipe(
    //     tap((bookId: number) => {
    //       // this.servicesReceipt.book = this.books.find(book => book.id === bookId);
    //     }),
    //     switchMap(() => this.bookForm.statusChanges),
    //     filter(() => this.bookForm.valid)
    //   )
    //   .subscribe({
    //     next: () => this.onSubmitBookStep(Number(this.bookForm.controls.book_id.value)),
    //     error: (error: any) => console.error(error)
    //   });

    this.courierAddressForm$ = this.courierForm.controls.address.valueChanges
      .pipe(
        tap(() => {
          this.courierForm.controls.delivery_tariff_id.reset();
          this.courierForm.controls.delivery_tariff_name.reset();
          this.courierForm.controls.delivery_company_id.reset();
          this.courierForm.controls.delivery_cost.reset();
          this.deliveryServiceList = [];
        }),
        // filter(() => this.courierForm.controls.address.dirty && this.courierForm.controls.address.valid)
        filter(() => this.courierForm.controls.address.valid)
      )
      .subscribe({
        next: (address: string) => {
          this.getDeliveryServices(address);
        },
        error: (error: any) => console.error(error)
      });

    this.deliveryIdForm$ = this.deliveryForm.controls.deliveryId.valueChanges
      .subscribe({
        next: (deliveryType: any) => {
          this.servicesReceipt = {
            ...this.servicesReceipt,
            receipt_row: this.servicesReceipt.receipt_row?.filter(row => row.title !== 'Доставка')
          };
          if (deliveryType === 0) {
            this.updateCourierDelivery();
          }
          else if (deliveryType === 1) {
            this.setReceiptRow({
              title: 'Доставка',
              description: [
                { label: 'Самовывоз', isFree: true },
              ],
              sum: 0,
            });
          }
        },
        error: (error: any) => console.error(error)
      });

    this.courierDeliveryTariffForm$ = this.courierForm.controls.delivery_tariff_id.valueChanges
      .pipe(
        tap(() => (this.selectedDelivery = undefined)),
        filter(() => this.courierForm.controls.delivery_tariff_id.valid)
      )
      .subscribe({
        next: (value: any) => {
          this.selectedDelivery = this.deliveryServiceList?.find(
            (delivery: DeliveryYandex) => delivery.tariffId === value
          );
          this.updateCourierDelivery();
        },
        error: (error: any) => console.error(error)
      });
  }

  getDeliveryServices(address: string) {
    const body = {
      address,
      book: this.bookOrder.book_id,
      book_count: this.bookOrder.book_count
    };
    this.deliveryIsSubmitted = true;
    this.deliveryService.getDeliveryServices(body)
      .subscribe({
        next: (data: any) => {
          this.deliveryServiceList = data;
          this.deliveryIsSubmitted = false;
        },
        error: () => (this.deliveryIsSubmitted = false)
      });
  }

  updateCourierDelivery(): void {
    this.courierForm.controls.delivery_tariff_name.setValue(this.selectedDelivery?.service_name);
    this.courierForm.controls.delivery_company_id.setValue(this.selectedDelivery?.service_id);
    this.courierForm.controls.delivery_cost.setValue(this.selectedDelivery?.cost.deliveryForCustomer);

    const rows = [];
    if (this.selectedDelivery) {
      rows.push({ label: this.selectedDelivery!.service_name, price: this.selectedDelivery?.cost.deliveryForCustomer });
    }

    this.setReceiptRow({
      title: 'Доставка',
      description: [
        { label: 'Курьерской службой' },
        ...rows,
      ],
      sum: this.selectedDelivery?.cost.deliveryForCustomer
    });

    if (this.courierForm.controls.address.valid && this.deliveryServiceList === null) {
      const address = this.courierForm.controls.address.value;
      this.getDeliveryServices(address);
    }
  }

  // onInitInternationalPhoneInput(): void {
  //   if (this.platformService.isBrowser()) {
  //     const input: Element = this.elementRef.nativeElement.querySelector('#phone');
  //     if (input) {
  //       this.helperService.getInternationalPhoneInputConfiguration()
  //         .pipe(first())
  //         .subscribe({
  //           next: (internationalPhoneInputConfiguration: intlTelInput.Options) => {
  //             // prettier-ignore
  //             this.internationalPhoneInput = intlTelInput(input, internationalPhoneInputConfiguration);
  //           },
  //           error: (error: any) => console.error(error)
  //         });
  //
  //       // this.internationalPhoneInput$?.unsubscribe();
  //       //
  //       // this.internationalPhoneInput$ = fromEvent(input, 'countrychange')
  //       //   .pipe(switchMap(() => of(this.internationalPhoneInput.getSelectedCountryData())))
  //       //   .subscribe({
  //       //     // prettier-ignore
  //       //     next: (countryData: CountryData) => this.courierForm.get('phoneCountry')?.setValue(countryData.iso2),
  //       //     error: (error: any) => console.error(error)
  //       //   });
  //     }
  //   }
  // }

  setProgressHistory(state: string): void {
    // this.progressHistory = {
    //   ...this.progressHistory,
    //   [state]: (() => {
    //     switch (state) {
    //       case 'initial': {
    //         return {
    //           bookFormValues: this.bookForm.value,
    //           bookDetail: this.bookDetail,
    //           printFormValues: this.printForm.value,
    //           bookOrder: this.bookOrder
    //         };
    //       }
    //       case 'delivery': {
    //         return {
    //           deliveryFormValues: this.deliveryForm.value,
    //           courierFormValues: this.courierForm.value,
    //           pickupFormValues: this.pickupForm.value,
    //           selectedDelivery: this.selectedDelivery
    //         };
    //       }
    //       default: {
    //         return {};
    //       }
    //     }
    //   })()
    // };
  }

  setReceiptRow(row: ServiceReceiptRow): void {
    this.servicesReceipt = this.serviceReceiptService.setRow(this.servicesReceipt, row);
  }

  onResetToInitial(): void {
    this.bookForm.reset();
    this.qtyForm.reset();

    this.booksSelected = undefined;
    this.bookDetail = undefined;

    this.servicesReceipt.receipt_row = [];

    this.progressMap = ['active', 'initial', 'initial', 'initial'];
    this.progressState = 'initial';
  }

  onReadyBookStep(): void {
    this.progressMap = ['active', 'initial', 'initial', 'initial'];
    this.progressState = 'initial';
  }

  onSubmitBookStep(bookId: number): void {
    this.booksSelected = this.books.find(book => book.id === bookId);
    if (!this.booksSelected || !this.booksSelected.availability) {
      this.onReadyBookStep();
    }
    else {
      this.servicesReceipt.book = this.booksSelected;
      this.printForYourselfService.getBookDetailById(this.booksSelected.id).subscribe({
        next: (bookDetail: BookDetail) => {
          this.bookDetail = bookDetail;
          this.bookOrder.user_id = this.booksSelected!.user_id;
          this.bookOrder.book_id = this.booksSelected!.id;
          this.onReadyQuantityStep();
        },
        error: (error: any) => {
          console.error(error);
          this.onReadyBookStep();
        }
      });
    }
  }

  onReadyQuantityStep(): void {
    const bookId = this.booksSelected!.id;
    const bookDetail = this.bookDetail;
    this.bookForm.controls.book_id.setValue(bookId, { emitEvent: false });

    this.progressMap = ['done', 'active', 'initial', 'initial'];
    this.progressState = 'quantity';

    this.setReceiptRow({
      title: 'Книга',
      description: [
        {
          label: 'Формат',
          value: bookDetail!.book_format
        },
        {
          label: 'Блок',
          value: `${bookDetail!.print_type} ${bookDetail!.page_count} ${this.getPagePlural(bookDetail!.page_count || 0)}`
        },
        {
          label: 'Обложка',
          value: bookDetail!.cover_type
        },
        {
          label: 'Вес',
          value: bookDetail!.unit_weight + ' гр/экз.'
        },
      ]
    });

    const weightValidator = (unitWeight: number) => {
      return (control: AbstractControl): ValidationErrors|null => {
        const totalWeight = (control.value ?? 0) * unitWeight;
        if (totalWeight > 35000) {
          return {'maxWeight': {'max': 35000, 'actual': totalWeight}};
        }
        return null;
      };
    };
    this.qtyForm.controls.book_count.clearValidators();
    this.qtyForm.controls.book_count.addValidators([
      Validators.required,
      Validators.min(30),
      weightValidator(bookDetail!.unit_weight),
    ]);
  }

  onSubmitQuantityStep(): void {
    if (this.helperService.getFormValidation(this.qtyForm)) {
      this.bookOrder.book_count = Number(this.qtyForm.controls.book_count.value);

      if (this.deliveryPickup) {
        this.onReadyDeliveryStep();
      }
      else {
        forkJoin([
          this.deliveryService.getDeliveryPickup(),
        ]).subscribe({
          next: (data: [DeliveryPickup[]]) => {
            const [deliveryPickup] = data;
            this.deliveryPickup = deliveryPickup;
            this.onReadyDeliveryStep();
          },
          error: (error: any) => console.error(error)
        });
      }
    }
  }

  onReturnQuantityStep(): void {
    this.setProgressHistory('delivery');

    // if ('initial' in this.progressHistory) {
    //   this.bookForm.patchValue(this.progressHistory.initial.bookFormValues);
    //   this.bookDetail = this.progressHistory.initial.bookDetail;
    //   this.printForm.patchValue(this.progressHistory.initial.printFormValues);
    //   this.bookOrder = this.progressHistory.initial.bookOrder;
    // }

    this.servicesReceipt = {
      ...this.servicesReceipt,
      receipt_row: this.servicesReceipt.receipt_row?.filter(
        row => !['Заказ', 'Тираж', 'Доставка'].includes(row.title)  // fixme Тираж
      )
    };

    this.progressMap = ['done', 'active', 'initial', 'initial'];
    this.progressState = 'quantity';
  }

  onReadyDeliveryStep(): void {
    this.deliveryForm.controls.deliveryId.reset();
    this.courierForm.controls.delivery_tariff_id.reset();
    this.courierForm.controls.delivery_tariff_name.reset();
    this.courierForm.controls.delivery_company_id.reset();
    this.courierForm.controls.delivery_cost.reset();
    this.deliveryServiceList = null;

    const unitPrice = Number(this.bookDetail?.one_book_price);
    const quantity: number = Number(this.qtyForm.get('book_count')?.value);
    const bookPrices: string = this.getRoundDecimal((unitPrice - (unitPrice * this.bookEditionPercent)) * quantity);

    // prettier-ignore
    this.setReceiptRow({
      title: 'Заказ',
      description: [
        {
          label: 'Тираж',
          value: `${this.bookOrder.book_count} экз.`,
          price: Number(bookPrices),
        },
        {
          label: 'Вес',
          value: `${Number(this.bookDetail?.unit_weight) * Number(this.bookOrder.book_count) / 1000} кг`,
        }
      ],
      sum: Number(bookPrices)
    });

    this.setProgressHistory('initial');

    this.progressMap = ['done', 'done', 'done', 'active'];
    this.progressState = 'delivery';
  }

  onSubmitDeliveryStep(): void {
    const bookFormIsValid = this.helperService.getFormValidation(this.bookForm);
    const printFormIsValid = this.helperService.getFormValidation(this.qtyForm);
    const deliveryFormIsValid = this.helperService.getFormValidation(this.deliveryForm);
    const courierFormIsValid = this.helperService.getFormValidation(this.courierForm);
    const pickupFormIsValid = this.helperService.getFormValidation(this.pickupForm);

    const deliveryByCourier = Number(this.deliveryForm.controls.deliveryId.value) === 0;

    // Create Order
    let body = {
      ...this.bookForm.value,
      ...this.qtyForm.value,
      ...this.deliveryForm.value,
    };
    if (deliveryByCourier) {
      body = {
        ...body,
        ...this.courierForm.value,
        phone: this.courierForm.controls.phone.value?.replaceAll(/\D/g, ''),
      };
    }
    else {
      body = {
        ...body,
        ...this.pickupForm.value,
      };
    }
    this.qtyFormIsSubmitted = true;
    this.printForYourselfService.createOrder(body).subscribe({
      next: (data: PrintForSelfOrder) => {
        this.bookOrder.id = data.id;

        // const bookCount: number = Number(this.printForm.get('book_count')?.value);
        //
        // // Сброс доставки, если изменили кол-во экземпляров
        // const newBookCount: number = Number(this.printForm.value.book_count);
        // const oldBookCount: number = Number(this.progressHistory.initial?.printFormValues?.book_count);
        // if (!!newBookCount && !!oldBookCount && newBookCount !== oldBookCount) {
        //   this.deliveryForm.reset();
        //   this.courierForm.reset();
        //   this.pickupForm.reset();
        //
        //   this.selectedDelivery = undefined;
        //
        //   this.setProgressHistory('delivery');
        // } else {
        //   // иначе возвращаем доставку в чек
        //   if (this.selectedDelivery) {
        //     this.setReceiptRow({
        //       title: 'Доставка',
        //       description: [
        //         { label: 'Курьерской службой', price: this.selectedDelivery?.cost.deliveryForCustomer }
        //       ],
        //       sum: this.selectedDelivery?.cost.deliveryForCustomer
        //     });
        //   }
        // }

        this.printForYourselfService.getCalculate(this.bookOrder.id)
          .subscribe({
            next: (data: PrintForSelfCalculate) => {
              this.calculateBook = data;
              this.qtyFormIsSubmitted = false;
              this.onReadyPayStep();
            },
            error: () => {
              this.qtyFormIsSubmitted = false;
            }
          });
        },
      error: () => {
        this.qtyFormIsSubmitted = false;
      }
    });

    // const courierOrPickupFormIsValid = [courierFormIsValid, pickupFormIsValid][
    //   +this.deliveryForm.get('deliveryId')?.value
    //   ];
    //
    // if (deliveryFormIsValid && courierOrPickupFormIsValid) {
    //   this.pickupFormIsSubmitting = true;
    //
    //   let body: any = {};
    //
    //   if (+this.deliveryForm.get('deliveryId')?.value) {
    //     body = {
    //       pickup_address_id: 1
    //     };
    //   } else {
    //     // prettier-ignore
    //     const countryData: CountryData = this.internationalPhoneInput?.getSelectedCountryData();
    //
    //     body = {
    //       ...this.courierForm.value,
    //       phone: (countryData?.dialCode || '').concat(this.courierForm.value.phone)
    //     };
    //   }
    //
    //   this.printForYourselfService.postDelivery(this.bookOrder.id, body)
    //     .pipe(
    //       switchMap((data: PrintForSelfOrder) => {
    //         this.bookOrder = data;
    //
    //         return this.printForYourselfService.getCalculate(this.bookOrder.id);
    //       })
    //     )
    //     .subscribe({
    //       next: (data: PrintForSelfCalculate) => {
    //         this.pickupFormIsSubmitting = false;
    //
    //         this.calculateBook = data;
    //
    //         this.setProgressHistory('delivery');
    //
    //         this.progressMap = ['done', 'done', 'done', 'done'];
    //         this.progressState = 'pay';
    //       },
    //       error: () => {
    //         this.pickupFormIsSubmitting = false;
    //       }
    //     });
    // }
  }

  onReturnToDelivery(): void {
    // if ('delivery' in this.progressHistory) {
    //   this.deliveryForm.patchValue(this.progressHistory.delivery.deliveryFormValues);
    //   this.courierForm.patchValue(this.progressHistory.delivery.courierFormValues);
    //   this.pickupForm.patchValue(this.progressHistory.delivery.pickupFormValues);
    //   this.selectedDelivery = this.progressHistory.delivery.selectedDelivery;
    //
    //   // const internationalTimeout: number = setTimeout(() => {
    //   //   this.internationalPhoneInput?.setCountry(this.courierForm.get('phoneCountry')?.value);
    //   //
    //   //   clearTimeout(internationalTimeout);
    //   // });
    // }

    this.progressMap = ['done', 'done', 'active', 'initial'];
    this.progressState = 'delivery';

    this.setReceiptRow({
      title: 'Доставка',
      description: [
        { label: 'Курьерской службой', price: this.selectedDelivery?.cost.deliveryForCustomer }
      ],
      sum: this.selectedDelivery?.cost.deliveryForCustomer
    });
  }

  onReadyPayStep(): void {
    this.setProgressHistory('delivery');

    this.progressMap = ['done', 'done', 'done', 'active'];
    this.progressState = 'pay';
  }

  onSubmitPayStep(): void {
    if (this.helperService.getFormValidation(this.termsForm)) {
      this.termsFormIsSubmitting = true;

      const orderId = this.bookOrder.id;
      const body = { ...this.termsForm.value };
      this.printForYourselfService.getPaymentLinks(orderId, body).subscribe({
        next: (printForSelfPayment: PrintForSelfPayment[]) => this.goToPayment(printForSelfPayment),
        error: () => (this.termsFormIsSubmitting = false)
      });

      // if (this.paymentsStatusIsActive) {
      //   this.printForYourselfService.getPaymentLinks(orderId).subscribe({
      //     next: (printForSelfPayment: PrintForSelfPayment[]) => this.goToPayment(printForSelfPayment),
      //     error: () => (this.termsFormIsSubmitting = false)
      //   });
      // } else {
      //   this.printForYourselfService.postComplete(orderId, body)
      //     .pipe(
      //       switchMap((printForSelfOrder: PrintForSelfOrder) => {
      //         this.bookOrder = printForSelfOrder;
      //
      //         return this.printForYourselfService.getPaymentLinks(orderId);
      //       })
      //     )
      //     .subscribe({
      //       // prettier-ignore
      //       next: (printForSelfPayment: PrintForSelfPayment[]) => this.goToPayment(printForSelfPayment),
      //       error: () => (this.termsFormIsSubmitting = false)
      //     });
      // }
    }
  }

  goToPayment(printForSelfPayment: PrintForSelfPayment[]): void {
    if (this.platformService.isBrowser()) {
      const window = this.platformService.getWindow();
      const sberbank: PrintForSelfPayment | undefined  = printForSelfPayment.find((payment: PrintForSelfPayment) => {
        return ['yookassa'/*, 'sberbank', 'paykeeper', 'balance'*/, 'test'].includes(payment.type);
      });
      if (sberbank?.link) {
        window.open(sberbank?.link, '_self');
      } else {
        this.snackbarService.error('Не найдено доступных способов оплаты', 4000);
        this.termsFormIsSubmitting = false;
      }
    }
  }

  getPagePlural(pageCount: number): string {
    return this.helperService.getPlural(['страница', 'страницы', 'страниц'], pageCount);
  }

  getRoundDecimal(value: string | number): string {
    return String(Math.ceil(Number(value)));
  }
}
