/** @format */
import { Book } from './book.model';

export interface Reference {
  age_ratings: ReferenceAgeRating[];
  genres: ReferenceGenre[];
}

export interface ReferenceAgeRating {
  id: number;
  title: string;
  description: string;
  tooltip?: string;
}

export interface ReferenceGenre {
  id: number;
  title: string;
}

export interface Service {
  title: string;
  service_key: ServiceKeys;
  service_type: string;
  cost: string | null;
  costRoyalty: string | number | null;
  activity_length: string | null;
  activity_end: string | null;
  distribution: string | null;
  reporting: string | null;
  printing: string | null;
}

export type ServiceKeys = 'print-for-self' | 'buy-book' | 'ebook' | 'distribution' | 'audiobook';

export interface ServiceReceipt {
  service_key: ServiceKeys;
  book?: Book;
  receipt_row?: ServiceReceiptRow[];
}

export interface ServiceReceiptRow {
  title: string;
  description?: ServiceReceiptRowDescription[];
  sum?: number;
  isFree?: boolean;
}

export interface ServiceReceiptRowDescription {
  label: string;
  value?: string;
  price?: number;
  isFree?: boolean;
}

export interface ServicesMap {
  label: string;
  icon: string;
  iconWidth: number;
  iconHeight: number;
  sliderLength: number;
  path: string;
}

export const getServiceMap = (service: string): ServicesMap => {
  switch (service) {
    case 'moderation': {
      return {
        label: 'Модерация',
        icon: 'service-moderation',
        iconWidth: 19,
        iconHeight: 18
      } as ServicesMap;
    }
    case 'docs':
    case 'contract':
    case 'offer': {
      return {
        label: 'Документы',
        icon: 'contract-yellow',
        iconWidth: 19,
        iconHeight: 20
      } as ServicesMap;
    }
    case 'log_print':
    case 'book_distribution': {
      return {
        label: 'Продажа в магазинах',
        icon: 'service-sale-in-stores',
        iconWidth: 21,
        iconHeight: 20
      } as ServicesMap;
    }
    case 'print-for-self':
    case 'print_for_yourself': {
      return {
        label: 'Печать для себя',
        icon: 'service-print-for-self',
        iconWidth: 17,
        iconHeight: 21,
        sliderLength: 5,
        path: '/office/services/print-for-self'
      };
    }
    case 'buy-book': {
      return {
        label: 'Выкуп складских остатков',
        icon: 'service-print-for-self',
        iconWidth: 17,
        iconHeight: 21,
        sliderLength: 1,
        path: '/office/services/buy-book'
      };
    }
    case 'ebook':
    case 'self_publish_ebook':
    case 'ebook_distribution':
    case 'sp_ebook_distribution': {
      return {
        label: 'Электронная книга',
        icon: 'service-ebook',
        iconWidth: 17,
        iconHeight: 21,
        sliderLength: 5,
        path: '/office/services/ebook'
      };
    }
    case 'distribution':
    case 'marketing':
    case 'marketing_order': {
      return {
        label: 'Маркетинговое продвижение',
        icon: 'service-marketing-promotion',
        iconWidth: 21,
        iconHeight: 18,
        sliderLength: 9,
        path: '/office/services/distribution'
      };
    }
    case 'audiobook':
    case 'audio_book':
    case 'self_publish_audiobook':
    case 'audio_distribution':
    case 'sp_audio_book_distribution': {
      return {
        label: 'Аудиокнига',
        icon: 'service-audio-book',
        iconWidth: 21,
        iconHeight: 21,
        sliderLength: 5,
        path: '/office/services/audiobook'
      };
    }
    default: {
      return {} as ServicesMap;
    }
  }
};

export const getButtonsByNotificationType = (type: string): string[] => {
  switch (type) {
    case 'AmoRelated\\PrintForYourself\\DeliveryTransferredNotification':
    case 'AmoRelated\\PrintForYourself\\PickupWaitingNotification':
    case 'AmoRelated\\PrintForYourself\\PrintForYourselfProductionCompletedNotification':
    case 'AmoRelated\\PrintForYourself\\ProductionStartedNotification':
    case 'AmoRelated\\AudioBookDistribution\\ModerationWaitNotification':
    case 'AmoRelated\\AudioBookDistribution\\ModerationSuccessNotification':
    case 'AmoRelated\\AudioBookDistribution\\DistributionPreparedNotification':
    case 'AmoRelated\\EbookDistribution\\ModerationWaitNotification':
    case 'AmoRelated\\EbookDistribution\\ModerationSuccessNotification':
    case 'AmoRelated\\EbookDistribution\\DistributionPreparedNotification':
    case 'AmoRelated\\BookDistribution\\DistributionOnPrintNotification':
    case 'AmoRelated\\BookDistribution\\ProductionWasStartNotification':
    case 'AmoRelated\\BookDistribution\\DesignLayoutIsReadyNotification':
    case 'AmoRelated\\ProductionStartedNotification':
    case 'AmoRelated\\PickupWaitingNotification':
    case 'AmoRelated\\PrintForYourselfProductionCompletedNotification':
    case 'AmoRelated\\ProductionWasStartedNotification':
    case 'AmoRelated\\DeliveryTransferredNotification':
    case 'AmoRelated\\ModerationSuccessNotification':
    case 'AmoRelated\\ModerationWaitNotification':
    case 'AmoRelated\\DesignLayoutIsReadyNotification':
    case 'AmoRelated\\DistributionOnPrintNotification':
    case 'AmoRelated\\DistributionPreparedNotification':
    case 'Marketing\\MarketingCompleted':
    case 'Marketing\\MarketingProductionCompletedNotification': {
      return ['callManager', 'goToService'];
    }
    case 'AmoRelated\\AudioBookDistribution\\DistributionOnSaleNotification':
    case 'AmoRelated\\EbookDistribution\\DistributionOnSaleNotification':
    case 'AmoRelated\\BookDistribution\\DistributionOnSaleNotification':
    case 'AmoRelated\\DistributionOnSaleNotification':
    case 'AmoRelated\\ProductionCompletedNotification': {
      return ['viewStatistics'];
    }
    case 'AmoRelated\\EbookDistribution\\ModerationFailedNotification':
    case 'AmoRelated\\AudioBookDistribution\\ModerationFailedNotification':
    case 'AmoRelated\\ModerationFailedNotification':
    case 'Marketing\\MarketingModerationFailedNotification': {
      return ['callManager', 'activateService'];
    }
    case 'AmoRelated\\DistributionEndNotification':
    case 'AmoRelated\\PrintForYourself\\DeliveredNotification':
    case 'Marketing\\MarketingIndividualWait':
    case 'AmoRelated\\BookDistribution\\BookOnModerationNotification':
    case 'AmoRelated\\BookDistribution\\RejectedByInvestNotification':
    case 'Marketing\\MarketingModerationWaitNotification':
    case 'AmoRelated\\DeliveredNotification':
    case 'AmoRelated\\DistributionWasSendNotification':
    case 'AmoRelated\\RejectedByInvestNotification': {
      return ['callManager'];
    }
    case 'AmoRelated\\PrintForYourself\\PaymentWaitNotification':
    case 'AmoRelated\\PaymentWaitNotification':
    case 'Marketing\\MarketingPaymentWaitNotification': {
      return ['payForOrder'];
    }
    case 'Contract\\UserReceivedContractNotification':
    case 'UserReceivedContractNotification': {
      return ['signContract'];
    }
    case 'Offer\\MustSignAttachmentNotification':
    case 'AmoRelated\\UserOfferWaitingApproveNotification':
    case 'Marketing\\MarketingAttachmentWaitNotification': {
      return ['resendEmail'];
    }
    case 'UserMustSignContractNotification': {
      return ['resendEmailContract'];
    }
    case 'Offer\\UserReceivedOfferNotification':
    case 'AmoRelated\\CopyrightWaitNotification':
    case 'UserReceivedOfferNotification': {
      return ['transferRights'];
    }
    default: {
      return [];
    }
  }
};
