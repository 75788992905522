/** @format */

import { Inject, Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackbarService } from './snackbar.service';
import { Router } from '@angular/router';
import { Configuration } from '../models';
import { APP_CONFIG } from './configuration.service';

const decodeArrayBuffer = (data: any) => JSON.parse(String.fromCharCode.apply(null, new Uint8Array(data) as any));

const buildMessageFromErrorRecurse = (e: any, depth: number) => {
  let m = '';
  if (e.message) {
    m += '—'.repeat(depth) + ' ' + e.message + "\n";
  }
  else if (e.messages) {
    for (const e2 of e.messages) {
      m += buildMessageFromErrorRecurse(e2, depth);
    }
  }
  if (e.previous) {
    m += buildMessageFromErrorRecurse(e.previous, depth+1);
  }
  return m;
};

@Injectable()
export class ApiService {
  constructor(
    @Inject(APP_CONFIG)
    private configuration: Configuration,
    private http: HttpClient,
    private router: Router,
    private snackbarService: SnackbarService
  ) {}

  handleErrors(response: any): Observable<any> {
    const error = response instanceof HttpErrorResponse
      ? response.error.constructor === ArrayBuffer ? decodeArrayBuffer(response.error) : response.error
      : null;

    switch (true) {
      case response.status === 404: {
        const generalError = 'Получен ответ сервера: 404\n- ' + (error.message || 'Запрошенный ресурс не найден');
        const message = generalError + '\n\nПожалуйста, сообщите об ошибке менеджеру.';
        this.snackbarService.error(message);
        break;
      }
      case response.status === 422: {
        this.messagesToSnackbar(error);
        break;
      }
      case response.status === 423 && response.url.includes('v1/users/registration'): {
        this.router.navigate(['/auth/login'])
          .then((): void => {
            this.messagesToSnackbar(error);
            this.snackbarService.success('Войдите используя ранее зарегистрированную учетную запись', 6000);
          });
        break;
      }
      case response.status === 424 && response.url.includes('login'): {
        this.router.navigate(['/auth/sms'], {
          queryParams: {
            token: response.error.data.verification_token
          }
        })
          .then(() =>
            this.snackbarService.error(response.error.data.message || 'Нераспознанная ошибка')
          );
        break;
      }
      case response.status === 424 && response.url.includes('v1/users/set-imported-author-password'): {
        this.router.navigate(['/auth/sms'], {
          queryParams: {
            token: response.error.data.verification_token
          }
        })
          .then(() =>
            this.snackbarService.error(response.error.data.message || 'Нераспознанная ошибка')
          );
        break;
      }
      case response.status === 424 && response.url.includes('password-reset'): {
        this.router.navigate(['/auth/sms'], {
            queryParams: {
              token: response.error.data.token
            }
          })
          .then(() =>
            this.snackbarService.error(response.error.data.message || 'Нераспознанная ошибка')
          );
        break;
      }
      case response.status === 429: {
        const generalError = 'Выполнено слишком много обращений к API!';
        const message = generalError + "\n" + 'Повторите действие через минуту.';
        this.snackbarService.info(message);
        break;
      }
      case response.status === 521: {
        const generalError = error.message || 'Ведутся технические работы';
        const message = 'Система временно недоступна. Приносим извинения за доставленные неудобства.';
        this.snackbarService.error(generalError);
        this.snackbarService.info(message);
        break;
      }
      default: {
        const generalError = `Получен ответ сервера: ${response.status}\n- ${response.statusText}`;
        this.snackbarService.error(generalError);
        this.messagesToSnackbar(error);
      }
    }

    return throwError(response);
  }

  /**
   * Display errors in snackbar
   */
  messagesToSnackbar(error: any[]|any):void {
    if ('errors' in error) {
      const generalError = (error.message ?? 'Нераспознанная ошибка');
      const errors = error.errors;
      for (const error of Object.keys(errors)) {
        if (typeof errors[error] === 'object') {
          const message = generalError + "\n- [" + error + ']\n-- ' + errors[error].join("\n-- ");
          this.snackbarService.error(message);
        } else {
          const message = generalError + "\n- [" + error + ']: ' + errors[error];
          this.snackbarService.error(message);
        }
      }
    }
    else if ('message' in error) {
      this.snackbarService.error(buildMessageFromErrorRecurse(error, 0));
    }
  }

  get(path: string, params: HttpParams = new HttpParams(), options?: any): Observable<any> {
    return this.http
      .get(this.configuration.apiOrigin + path, { params, ...options })
      .pipe(catchError(error => this.handleErrors(error)));
  }

  put(path: string, body: object|null): Observable<any> {
    return this.http
      .put(this.configuration.apiOrigin + path, body)
      .pipe(catchError(error => this.handleErrors(error)));
  }

  post(path: string, body: object|null): Observable<any> {
    return this.http
      .post(this.configuration.apiOrigin + path, body)
      .pipe(catchError(error => this.handleErrors(error)));
  }

  delete(path: string): Observable<any> {
    return this.http
      .delete(this.configuration.apiOrigin + path)
      .pipe(catchError(error => this.handleErrors(error)));
  }
}
