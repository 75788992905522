/** @format */

import { Book } from 'src/app/core';
import { AccessMessageMap } from './access-message.model';

export interface BookDetail {
  book_format: string;
  print_type: string;
  cover_type: string;
  page_count: number;
  unit_weight: number;
  one_book_price: number;
  in_stock?: number;
}

// Формат книги (A4,A5,A6 и т.д.)
export interface BookFormat {
  id: number; // Идентификатор формата
  title: string; // Название формата
  description: string; // Описание формата
  width: number; // Ширина
  length: number; // Длина
  tooltip: string; // Дополнительная подсказка
}

// Доступные форматы для данного типа обложки
export interface BookCoverFormat {
  id: number; // Идентификатор формата
  title: string; // Название формата
  description: string; // Описание формата
  width: number; // Ширина
  length: number; // Длина
  tooltip: string; // Дополнительная подсказка
}

// Тип обложки книги (твердая/мягкая/глянцевая и т.д.)
export interface BookCoverType {
  id: number; // Идентификатор типа
  title: string; // Название типа
  description: string; // Описание типа
  tooltip: string; // Дополнительная подсказка
  formats: BookCoverFormat[]; // доступные форматы
}

// Данные о тираже
// export interface BookEdition {
//   book_count: number; // Кол-во книг
//   page_count: number; // Кол-во страниц
// }

export interface PrintForSelfOrder {
  id: number; // Уникальный идентификатор
  user_id: number; // Идентификатор пользователя
  book_id: number; // Идентификатор книги
  book_count: number; // Количество экземпляров книги
  price: number | null; // Цена за услугу
  delivery_type: string | null; // Тип доставки
  delivery_address: string | null; // Адрес пункта самовывоза или адрес доставки
  agreement: boolean; // Согласился ли пользователь с условиями
}

export interface PrintForSelfPrintDetail {
  bookFormat: BookFormat; // Формат книги
  coverType: BookCoverType; // Тип обложки книги
  printType: string; // Вид печати
  book_count: number; // Кол-во книг
  page_count: number; // Кол-во страниц
  // edition: BookEdition;
}

// Набор цен за услугу
export interface PrintForSelfCalculate {
  id: number;
  book_id: number;
  created_at: string;
  updated_at: string;
  print: {  // Параметры печати
    price: number; // Цена
    title: string;
    description: string; // Описание позиции в чека
    data: PrintForSelfPrintDetail;
  };
  delivery: {  // Информация по доставке
    price: number; // Цена
    title: string;
    description: string; // Описание позиции в чека
  };
  totalPrice: number; // Итоговая цена за услугу
  book?: Book;
}

export interface PrintForSelfPayment {
  type: string; // Тип оплаты
  title: string; // Наименование типа оплаты
  description: string; // Описание типа оплаты
  available: boolean; // Возможность оплаты
  link: string; // Ссылка перенаправления пользователя для оплаты
}

export const PrintForSelfAccessMessageMap: AccessMessageMap = {
  book_copyright_not_transferred: {
    title: 'Подключение услуги в данный момент невозможно',
    text: 'Услуга печати для себя для выбранного произведения недоступна, т.к. оферта по бумажной дистрибуции не была принята и подписана',
    icon: 'status-fail',
    iconWidth: 40,
    iconHeight: 40
  },
  no_design_layout: {
    title: 'Подключение услуги в данный момент невозможно',
    text: 'Услуга печати для себя для выбранного произведения недоступна, т.к. работы по созданию книжного макета книги не были завершены или отсутствуют сведения об их завершении.',
    icon: 'status-await',
    iconWidth: 40,
    iconHeight: 40
  },
  service_in_production: {
    title: 'Подключение услуги в данный момент невозможно',
    text: `Услуга печати для себя для выбранного произведения недоступна, т.к. услуга уже заказана и оплачена, а мы приступили к ее выполнению. Дождитесь отгрузки тиража, чтобы заказать услугу повторно.`,
    icon: 'status-success',
    iconWidth: 40,
    iconHeight: 40
  }
};
