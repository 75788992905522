/** @format */

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fromEvent, of, Subscription, timer } from 'rxjs';
import { PlatformService, Service, getServiceMap, ServicesMap } from '../../../../core';
import { slideAnimation } from '../../../../app-animations';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-services-carousel',
  templateUrl: './services-carousel.component.html',
  styleUrls: ['./services-carousel.component.scss'],
  animations: [slideAnimation]
})
export class ServicesCarouselComponent implements OnInit, OnDestroy {
  @ViewChild('slider') slider: ElementRef | undefined;

  @Input()
  set appService(service: Service | undefined) {
    this.serviceSlideActive = 0;

    this.service = service;
    this.serviceMap = getServiceMap(service?.service_key as string);
  }
  @Input()
  set appSlideType(type: string | undefined) {
    this.slideType = type;
  }

  timer$: Subscription | undefined;
  timerScheduler = 4000;

  service: Service | undefined;
  serviceMap: ServicesMap | undefined;
  serviceSlideActive = 0;

  slideType?: string;

  /**
   * wheelReducer - is a number of times that mouse wheel will be triggered (overall)
   * wheelBound - is a number of times that mouse wheel should trigger to do slide
   */
  wheelReducer = 0;
  wheelBound = 1;
  wheelTimer$: Subscription | undefined;
  wheelScroll$: Subscription | undefined;

  constructor(private platformService: PlatformService) {}

  ngOnInit(): void {
    this.timer$ = timer(this.timerScheduler, this.timerScheduler)
      .pipe(
        switchMap(() =>
          of(
            this.serviceSlideActive !==
              getServiceMap(this.service?.service_key as string).sliderLength - 1
          )
        )
      )
      .subscribe({
        next: (next: boolean) => this.goToSlide(next ? this.serviceSlideActive + 1 : 0),
        error: (error: any) => console.error(error)
      });

    if (this.platformService.isBrowser()) {
      /**
       * Additional time for DOM initialization
       */
      this.wheelTimer$ = timer(1000).subscribe({
        next: () => {
          // @ts-ignore
          // prettier-ignore
          this.wheelScroll$ = fromEvent<WheelEvent>(this.slider?.nativeElement, 'wheel', { passive: false })
            .pipe(
              tap((event: WheelEvent) => {
                if (!(getServiceMap(this.service?.service_key as string).sliderLength === 1
                || (this.serviceSlideActive + 1 >= getServiceMap(this.service?.service_key as string).sliderLength
                  && event.deltaY > 0))) event.preventDefault();
              }),
              debounceTime(50)
            )
            .subscribe({
              next: (event: WheelEvent) => {
                event.preventDefault();

                this.wheelReducer++;

                if (this.wheelReducer >= this.wheelBound) {
                  this.wheelReducer = 0;

                  this.onChange(
                    event.deltaY > 0 ? this.serviceSlideActive + 1 : this.serviceSlideActive - 1
                  );
                }
              },
              error: (error: any) => console.error(error)
            });
        },
        error: (error: any) => console.error(error)
      });
    }
  }

  ngOnDestroy(): void {
    [this.timer$, this.wheelTimer$, this.wheelScroll$].forEach($ => $?.unsubscribe());
  }

  onChange(i: number): void {
    this.timer$?.unsubscribe();

    this.goToSlide(i);
  }

  goToSlide(i: number): void {
    if (i >= 0 && i < getServiceMap(this.service?.service_key as string).sliderLength) {
      this.serviceSlideActive = i;
    }
  }
}
