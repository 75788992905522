/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Snack, SnackbarService } from '../../../core';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit, OnDestroy {
  snackbar$: Subscription | undefined;

  snacks: Snack[] = [];

  constructor(private snackbarService: SnackbarService) {}

  private addNotification(snack: Snack): void {
    snack.message = snack.message?.trim();
    this.snacks.push(snack);
    if (snack.timeout !== 0) {
      setTimeout(() => this.onClose(snack), snack.timeout);
    }
  }

  ngOnInit(): void {
    this.snackbar$ = this.snackbarService
      .getObservable()
      .subscribe(snack => this.addNotification(snack));
  }

  ngOnDestroy(): void {
    this.snackbar$?.unsubscribe();
  }

  onClose(snack: Snack): void {
    this.snacks = this.snacks.filter(({ id }) => id !== snack.id);
  }
}
