/** @format */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, Book } from 'src/app/core';
import {
  BookDetail,
  KeysAccessMessageMap,
  PrintForSelfCalculate,
  PrintForSelfOrder,
  PrintForSelfPayment
} from '..';

@Injectable({
  providedIn: 'root'
})
export class BuyBookService {
  constructor(private apiService: ApiService) {}

  getBooks(): Observable<Book[]> {
    return this.apiService.get('/v1/buy-book/books').pipe(
      map((data: any) => data.data),
      map(data => {
        interface BooksData {
          book: Book;
          flag: KeysAccessMessageMap;
          availability: boolean;
        }

        return data.map((booksData: BooksData) => {
          return {
            ...booksData,
            ...booksData.book
          };
        });
      })
    );
  }

  createOrder(body: any): Observable<PrintForSelfOrder> {
    return this.apiService
      .post('/v1/buy-book/create', body)
      .pipe(map((data: any) => data.data));
  }

  postDelivery(orderId: number, body: any): Observable<PrintForSelfOrder> {
    return this.apiService
      .post(`/v1/buy-book/${orderId}/delivery`, body)
      .pipe(map((data: any) => data.data));
  }

  getCalculate(orderId: number): Observable<PrintForSelfCalculate> {
    return this.apiService
      .get(`/v1/buy-book/${orderId}/check`)
      .pipe(map((data: any) => data.data));
  }

  postComplete(orderId: number, body: any): Observable<PrintForSelfOrder> {
    return this.apiService
      .post(`/v1/buy-book/${orderId}/complete`, body)
      .pipe(map((data: any) => data.data));
  }

  getPaymentLinks(orderId: number, body: any): Observable<PrintForSelfPayment[]> {
    return this.apiService
      .post(`/v1/buy-book/${orderId}/payment`, body)
      .pipe(map((data: any) => data.data));
  }

  getBookDetailById(bookId: number): Observable<BookDetail> {
    return this.apiService
      .get(`/v1/buy-book/books/${bookId}/details`)
      .pipe(map((data: any) => data.data));
  }
}
